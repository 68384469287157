import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organization: null,
  integrations: [],
  integrationsAlias: [],
  orgUsersMutate: null, // Mutate (update/refresh table with users)
  modalEditUserOpen: false, // Show/Hide modal to edit a user
  selectedEditUser: null, // User's object with details a bout the current user to edit
  orgTeamsMutate: null, // Mutate (update/refresh table with teams)
  modalEditTeamOpen: false, // Show/hide modal to edit a team
  teamSelectedEdit: null // Team selected to edit
};

export const orgSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, org) => {
      state.organization = org.payload;
    },
    setIntegrations: (state, org) => {
      state.integrations = org.payload;
    },
    setIntegrationsAlias: (state, org) => {
      state.integrationsAlias = org.payload;
    },
    setMutateOrgUsers: (state, mutate) => {
      state.orgUsersMutate = mutate.payload;
    },
    setModalEditUserOpen: (state, mutate) => {
      state.modalEditUserOpen = mutate.payload;
    },
    setSelectedEditUser: (state, mutate) => {
      state.selectedEditUser = mutate.payload;
    },
    setMutateOrgTeams: (state, mutate) => {
      state.orgTeamsMutate = mutate.payload;
    },
    setModalEditTeamOpen: (state, mutate) => {
      state.modalEditTeamOpen = mutate.payload;
    },
    setTeamSelectedEdit: (state, mutate) => {
      state.teamSelectedEdit = mutate.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setOrganization,
  setIntegrations,
  setIntegrationsAlias,
  setMutateOrgUsers,
  setModalEditUserOpen,
  setSelectedEditUser,
  setMutateOrgTeams,
  setModalEditTeamOpen,
  setTeamSelectedEdit
} = orgSlice.actions;

export default orgSlice.reducer;
