import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categorySelected: null,
  categories: [],
  mutateCategories: null, // Mutate (SWR)
  categorySubcategories: [], // Current selected category => subcategories
  refreshCategorySubcategories: null, // Refresh subcategories (AXIOS)
  modalEditCategoryOpen: false, // Show/Hide modal to edit a category
  modalEditSubcategoryOpen: false, // Show/Hide modal to edit a subcategory
  subCategorySelected: null // Selected subcategory
};

export const flagCatSlice = createSlice({
  name: 'flag',
  initialState,
  reducers: {
    setCategorySelected: (state, category) => {
      state.categorySelected = category.payload;
    },
    setFlagCategories: (state, categories) => {
      state.categories = categories.payload;
    },
    setFlagCategoriesMutate: (state, mutate) => {
      state.mutateCategories = mutate.payload;
    },
    removeCategory: (state, category) => {
      // Find comment's index
      const cIndex = state.categories.findIndex((x) => {
        return x._id === category.payload._id;
      });

      state.flagComments.splice(cIndex, 1);
    },
    setFlagSubcategories: (state, subcategories) => {
      state.categorySubcategories = subcategories.payload;
    },
    setRefreshFlagSubcategories: (state, mutate) => {
      state.refreshCategorySubcategories = mutate.payload;
    },
    setSubCategorySelected: (state, subCategory) => {
      state.subCategorySelected = subCategory.payload;
    },
    removeSubcategory: (state, subcategory) => {
      // Find comment's index
      const cIndex = state.categories.findIndex((x) => {
        return x._id === subcategory.payload._id;
      });

      state.flagComments.splice(cIndex, 1);
    },
    setModalEditCategoryOpen: (state, open) => {
      state.modalEditCategoryOpen = open.payload;
    },
    setModalEditSubcategoryOpen: (state, open) => {
      state.modalEditSubcategoryOpen = open.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setFlagCategories,
  removeCategory,
  setCategorySelected,
  setFlagSubcategories,
  removeSubcategory,
  setFlagCategoriesMutate,
  setRefreshFlagSubcategories,
  setSubCategorySelected,
  setModalEditCategoryOpen,
  setModalEditSubcategoryOpen
} = flagCatSlice.actions;

export default flagCatSlice.reducer;
