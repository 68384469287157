import React from 'react';
import { SWRConfig } from 'swr';
import NProgress from 'nprogress';
import Router from 'next/router';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import { appWithTranslation } from 'next-i18next';

// Global CSS
import '../styles/globals.css';
import 'nprogress/nprogress.css'; // nprogress
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css'; // react carousel (react-slick)
import 'slick-carousel/slick/slick-theme.css'; // react carousel theme (react-slick)

// Components
// import ErrorBoundary from '../components/ErrorBoundary';

// *** START: NPROGRESS GLOBAL CONF ****
// Bind events for "nprogress"
Router.events.on('routeChangeStart', () => {
  // console.log(`Loading: ${url}`)
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
// *** END: NPROGRESS GLOBAL CONF ****

// Fetcher to use with SWR

const fetcher = (...urls) => {
  // console.log('-> fetcher urls: ', urls);
  const f = (u) => fetch(u).then((r) => r.json());

  // console.log(
  //   '----> urls: ',
  //   urls.length,
  //   ', ',
  //   typeof urls,
  //   ', ',
  //   Array.isArray(urls),
  //   ', ',
  //   urls[0]
  // );

  if (typeof urls[0] === 'object' && Array.isArray(urls[0])) {
    return Promise.all(urls[0].map(f));
  } else {
    return f(urls);
  }

  // Previous version code (didn't enter in urls.length > 1, because return array [0] and inside other array with URLs)
  // if (urls.length > 1) {
  //   console.log('----> p1111');
  //   return Promise.all(urls.map(f));
  // } else {
  //   console.log('----> p2222');
  //   return f(urls);
  // }
};

const MyApp = ({ Component, pageProps }) => (
  <Provider store={store}>
    <SWRConfig value={{ fetcher }}>
      <Component {...pageProps} />
    </SWRConfig>
  </Provider>
);

export default appWithTranslation(MyApp);
