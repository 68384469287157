import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mapInstance: null,
  polygons: [],
  polygonSelected: null,
  modalEditPolygonOpen: false, // Show/Hide modal to edit a polygon
  mutatePolygons: null
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapInstance: (state, map) => {
      console.log('-> set map instance: ', map);
      state.mapInstance = map.payload;
    },
    setPolygons: (state, polygons) => {
      state.polygons = polygons.payload;
    },
    setPolygonSelected: (state, polygon) => {
      state.polygonSelected = polygon.payload;
    },
    setModalEditPolygonOpen: (state, open) => {
      state.modalEditPolygonOpen = open.payload;
    },
    setMapPolygonsMutate: (state, mutate) => {
      state.mutatePolygons = mutate.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setMapInstance,
  setPolygons,
  setPolygonSelected,
  setModalEditPolygonOpen,
  setMapPolygonsMutate
} = mapSlice.actions;

export default mapSlice.reducer;
