import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardsMutate: null, // Mutate dashboards
  dashboardDetailsMutate: null // Mutate dashboard details / charts
};

export const dashboardsSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setMutateDashboards: (state, mutate) => {
      state.dashboardsMutate = mutate.payload;
    },
    setMutateDashboardDetails: (state, mutate) => {
      state.dashboardDetailsMutate = mutate.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setMutateDashboards, setMutateDashboardDetails } =
  dashboardsSlice.actions;

export default dashboardsSlice.reducer;
