import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mutateSurveys: null, // Mutate (update/refresh table with surveys)
  survey: null,
  surveyMutate: null, // Mutate survey details (re-fetch server)
  question: null, // Selected question
  questionTitle: null, // Selected question title
  questionChoices: [] // Set question choices
};

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setMutateSurveys: (state, mutate) => {
      state.mutateSurveys = mutate.payload;
    },
    setSurvey: (state, survey) => {
      state.survey = survey.payload;
    },
    setSurveyMutate: (state, mutate) => {
      state.surveyMutate = mutate.payload;
    },
    // Set selected question
    setQuestion: (state, question) => {
      state.question = question.payload;
    },
    setQuestionTitle: (state, title) => {
      state.questionTitle = title.payload;
    },
    setQuestionChoices: (state, choices) => {
      state.questionChoices = choices.payload;
    },
    appendQuestionChoice: (state, choice) => {
      state.questionChoices.push(choice.payload);
    },
    deleteQuestionChoice: (state, choiceIndex) => {
      state.questionChoices.splice(choiceIndex.payload, 1); // Remove element at index
    },
    updateQuestionChoice: (state, choice) => {
      // default
      // Remove default for all
      // console.log('-> key: ', choice.payload.key, ', ', choice.payload.value);
      if (choice.payload.key === 'default') {
        // Set all default as => false
        for (let cIndex = 0; cIndex < state.questionChoices.length; cIndex++) {
          state.questionChoices[cIndex].default = false;
        }
      }

      state.questionChoices[choice.payload.index][choice.payload.key] =
        choice.payload.value;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setMutateSurveys,
  setSurvey,
  setSurveyMutate,
  setQuestion,
  setQuestionTitle,
  setQuestionChoices,
  appendQuestionChoice,
  updateQuestionChoice,
  deleteQuestionChoice
} = surveySlice.actions;

export default surveySlice.reducer;
