import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attachments: []
};

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    setAttachments: (state, attachments) => {
      state.attachments = attachments.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAttachments } = attachmentsSlice.actions;

export default attachmentsSlice.reducer;
