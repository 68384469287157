import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import orgReducer from './reducers/organization';
import flagReducer from './reducers/flag';
import flagCategoryReducer from './reducers/flagCategories';
import attachmentsReducer from './reducers/attachments';
import surveyReducer from './reducers/survey';
import assetReducer from './reducers/asset';
import mapReducer from './reducers/map';
import surveyv2Reducer from './reducers/surveyv2';
import analytics from './reducers/analytics';

export const store = configureStore({
  reducer: {
    user: userReducer,
    organization: orgReducer,
    flag: flagReducer,
    survey: surveyReducer,
    surveyv2: surveyv2Reducer,
    flagcategories: flagCategoryReducer,
    attachments: attachmentsReducer,
    asset: assetReducer,
    map: mapReducer,
    analytics: analytics
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
