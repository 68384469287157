import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assetMutate: null, // Mutate (update/refresh table with assets)
  modalEditAssetOpen: false, // Show/Hide modal to edit a user
  selectedEditAsset: null // User's object with details a bout the current user to edit
};

export const assetsSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setMutateAsset: (state, mutate) => {
      state.assetMutate = mutate.payload;
    },
    setModalEditAssetOpen: (state, mutate) => {
      state.modalEditAssetOpen = mutate.payload;
    },
    setSelectedEditAsset: (state, mutate) => {
      state.selectedEditAsset = mutate.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setMutateAsset, setModalEditAssetOpen, setSelectedEditAsset } =
  assetsSlice.actions;

export default assetsSlice.reducer;
