import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mutateSurveys: null, // Mutate (update/refresh table with surveys)
  mutateSurveyDetails: null, // Mutate survey details (re-fetch server)
  questionSelected: null, // Survey selected question,
  questionSelectedId: null // Selected question id
};

export const surveyv2Slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setMutateSurveys: (state, mutate) => {
      state.mutateSurveys = mutate.payload;
    },
    setSurvey: (state, survey) => {
      state.survey = survey.payload;
    },
    setMutateSurveyDetails: (state, mutate) => {
      state.mutateSurveyDetails = mutate.payload;
    },
    // Set selected question
    setQuestionSelected: (state, question) => {
      state.questionSelected = question.payload;
    },
    // Set selected question
    setQuestionSelectedId: (state, question) => {
      state.questionSelectedId = question.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setMutateSurveys,
  setSurvey,
  setMutateSurveyDetails,
  setQuestionSelected,
  setQuestionSelectedId
} = surveyv2Slice.actions;

export default surveyv2Slice.reducer;
