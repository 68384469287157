import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  flag: null,
  mutateFlag: null,
  flagComments: [],
  mutateFlagComments: null,
  flagPriority: null,
  polygons: [],
  polygonSelected: null,
  modalEditPolygonOpen: false, // Show/Hide modal to edit a polygon
  mutatePolygons: null,
  addFlagClient: null // Selected client when adding a new flag from CRM
};

export const flagSlice = createSlice({
  name: 'flag',
  initialState,
  reducers: {
    setFlag: (state, flag) => {
      state.flag = flag.payload;
    },
    updateFlag: (state, flag) => {
      state.flag = { ...state.flag, ...flag.payload };
    },
    setMutateFlag: (state, mutate) => {
      state.mutateFlag = mutate.payload;
    },
    setFlagPriority: (state, priority) => {
      state.flagPriority = priority.payload;
    },
    setFlagComments: (state, comments) => {
      state.flagComments = comments.payload;
    },
    setMutateFlagComments: (state, mutate) => {
      state.mutateFlagComments = mutate.payload;
    },
    addComment: (state, comment) => {
      state.flagComments.unshift(comment.payload);
    },
    removeComment: (state, comment) => {
      // Find comment's index
      const cIndex = state.flagComments.findIndex((x) => {
        return x._id === comment.payload._id;
      });

      state.flagComments.splice(cIndex, 1);
    },
    setPolygons: (state, polygons) => {
      state.polygons = polygons.payload;
    },
    setPolygonSelected: (state, polygon) => {
      state.polygonSelected = polygon.payload;
    },
    setModalEditPolygonOpen: (state, open) => {
      state.modalEditPolygonOpen = open.payload;
    },
    setFlagPolygonsMutate: (state, mutate) => {
      state.mutatePolygons = mutate.payload;
    },
    setAddFlagClient: (state, mutate) => {
      state.addFlagClient = mutate.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setFlag,
  updateFlag,
  setMutateFlag,
  setFlagPriority,
  setFlagComments,
  setMutateFlagComments,
  addComment,
  removeComment,
  setPolygons,
  setPolygonSelected,
  setModalEditPolygonOpen,
  setFlagPolygonsMutate,
  setAddFlagClient
} = flagSlice.actions;

export default flagSlice.reducer;
